import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TruckService {
  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient
  ) {}

  createTruck(data: any) {
    return this.http.post(`${this.environment.apiUrl}trucks`, data);
  }

  updateTruck(data: any, id: string) {
    return this.http.patch(`${this.environment.apiUrl}trucks/${id}`, data);
  }

  getTruckDetail(id: any): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}trucks/${id}`);
  }

  countTrunks() {
    return this.http.get(`${this.environment.apiUrl}trucks/count`);
  }

  getAssignTruckDetail(id: any): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}trucks/assigned/${id}`);
  }
}
