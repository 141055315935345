  <!--order header start here-->
  <div class="order-header d-flex justify-content-between align-items-center">
    <div class="back-btn">
      <a routerLink="/truck">
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1133_857)">
            <path
              d="M9.725 3.725L8.25 2.25L0 10.5L8.25 18.75L9.725 17.275L2.95 10.5L9.725 3.725Z"
              fill="#025C54"
            />
          </g>
          <defs>
            <clipPath id="clip0_1133_857">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
        Back</a
      >
    </div>
    <div class="order-heading">
      <h1>
        Truck Details
      </h1>
      <ul>
        <li>
          Total Order : <strong>{{ truckData?.orders?.length }}</strong>
        </li>
        <li>
          Status : <strong class="order">{{ truckData.status }}</strong>
        </li>
      </ul>
    </div>
    <div class="order-action"></div>
  </div>
  <!--order header end here-->

<!--order detail start here -->
<div class="order-details">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <!--customer detail start here-->
        <div class="card">
          <div class="card-body">
            <div
              class="card-header-custom d-flex justify-content-between align-items-start"
            >
              <div class="card-heading-details">
                <h3>Truck Detail</h3>
              </div>
            </div>
            <div class="customer-details">
              <ul>
                <li>
                  <strong>Truck Number</strong>
                  {{ truckData?.truckId?.truckNumber }}
                </li>
              </ul>
            </div>
            <div class="col-lg-12 mb-3">
              <div class="truck-limit-status">
                <ul class="d-flex">
                  <li class="limit-status total-limit">Total Limit : {{ truckData.totalLimit }}KG</li>
                  <li class="limit-status available-limit">Available Limit : {{ truckData.availableLimit }}KG</li>
                  <li class="limit-status used-limit">Used Limit : {{ truckData.usedLimit }}KG</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-lg-12 d-flex justify-content-between align-items-center main-heading"
        >
          <div class="breadcrumbs">
            <h1>Orders</h1>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="card-box table-card no-padding">
            <bst-frontend-datatable
              [dataConfig]="dataConfig"
              [dataSourceKey]="page"
              [dataUrl]="nextPageUrl"
            ></bst-frontend-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
