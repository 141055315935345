import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient
  ) {}

  getAddresses() {
    return this.http.get(`${this.environment.apiUrl}addresses`);
  }
  getAddress(id: string) {
    return this.http.get(`${this.environment.apiUrl}addresses/${id}`);
  }
  createAddress(address: any) {
    return this.http.post(`${this.environment.apiUrl}addresses`, address);
  }
  updateAddress(address: any, id: string) {
    return this.http.put(`${this.environment.apiUrl}addresses/${id}`, address);
  }
  deleteAddress(id: string) {
    return this.http.delete(`${this.environment.apiUrl}addresses/${id}`);
  }
}
