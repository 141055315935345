export const CONSTANT = {
  viewerOptions: {
    navbar: false,
    zIndex: 9999,
    toolbar: {
      zoomIn: {
        show: 4,
        size: 'large',
      },
      zoomOut: {
        show: 4,
        size: 'large',
      },
      oneToOne: false,
      reset: {
        show: 4,
        size: 'large',
      },
      prev: {
        show: 4,
        size: 'large',
      },
      play: {
        show: 4,
        size: 'large',
      },
      next: {
        show: 4,
        size: 'large',
      },
      rotateLeft: false,
      rotateRight: {
        show: 4,
        size: 'large',
      },
      flipHorizontal: false,
      flipVertical: false,
    },
  },
  roles: {
    mentor: 'Mentor',
    management: 'Management',
    user: 'User',
    sjomanager: 'SJOManager',
  },
  addressType: {
    office: 'BST Office',
    godown: 'BST Godown',
    other: 'Other',
  },
  customerAddressType: {
    company: 'Company',
    godown: 'Godown',
    other: 'Other',
  },
  companyTypeOem: 'OEM',
  companyTypeProcess: 'Process',
  project: 'projects',
  reports: 'reports',
  multipleChoice: 'MultipleChoice',
  checkbox: 'Checkbox',
  text: 'Text', //updated
  number: 'Number', //updated
  logAdmin: 'Admin',
  logWeb: 'Web',

  //image viewer
  page: 'Page',
  answerOptions: [
    'Number',
    'Text',
    'date',
    'time',
    'DateTime',
    'Location',
    'User',
  ],
  //Report
  infopage: 'info',
  titlepage: 'title',
  pages: 'pages',
  questionpage: 'question',
  notStarted: 'Not Started',
  progress: 'In Progress',
  completed: 'Completed',
  //Schedule
  myschedule: 'My Schedule',
  manageSchedule: 'Manage Schedule',
  dueSchedule: 'Due Schedule',
  //Checklist
  question: 'Question',
  answer: 'Answer',
  section: 'Section',
  // for excel upload
  excelnumber: 'number',
  select: 'select',
  defaultValues: ['True', 'False'],
  default: 'Default',
  //-------------media
  // =>Types
  imageType: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
  videoType: ['video/mp4', 'video/quicktime'],
  documentType: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'text/csv',
  ],
  // =>Sizes
  imageSize: 5, // in MB
  videoSize: 100, // in MB
  documentSize: 50, // in MB

  //--------------schedule
  scheduleStatusType: {
    active: 'Active',
    overdue: 'Overdue',
    inactive: 'Inactive',
    completed: 'Completed',
    overdueInProgress: 'Overdue inprogress',
    overdueComplete: 'Overdue completed',
    inprogress: 'In progress',
    delete: 'Deleted',
  },
  dateConvertFormat: 'yyyy-MM-dd',
  timeConvertFormat: 'HH:mm:ss',
  dateTimeConvertFormat: 'yyyy-MM-dd HH:mm',
  databaseTimeFormat: 'HH:mm',

  //---------------------dynamic title page
  checklistDataTypes: [
    {
      id: 0,
      name: 'Title Page Properties',
      type: 'Module',
      isCollapsible: true,
      set: false,
      show: false,
    },
    {
      id: 1,
      name: 'Multiple choice Answer',
      type: 'MultipleChoice',
      isCollapsible: true,
      set: false,
      show: true,
    },
    {
      id: 2,
      name: 'Multiple checkbox answer',
      type: 'Checkbox',
      isCollapsible: true,
      set: false,
      show: true,
    },
    {
      id: 3,
      name: 'Number',
      type: 'Number',
      isCollapsible: false,
      set: false,
      show: true,
    },
    {
      id: 4,
      name: 'Text',
      type: 'Text',
      isCollapsible: false,
      set: false,
      show: true,
    },
    {
      id: 5,
      name: 'Date',
      type: 'date',
      isCollapsible: false,
      set: true,
      show: true,
    },
    {
      id: 6,
      name: 'Time',
      type: 'time',
      isCollapsible: false,
      set: true,
      show: true,
    },
    {
      id: 7,
      name: 'Date & Time',
      type: 'DateTime',
      isCollapsible: false,
      set: true,
      show: true,
    },
    {
      id: 8,
      name: 'Location',
      type: 'Location',
      isCollapsible: false,
      set: true,
      show: true,
    },
    {
      id: 9,
      name: 'User',
      type: 'User',
      isCollapsible: true,
      set: true,
      show: false,
    },
  ],
  titlePageProperties: [
    {
      id: 1,
      name: 'Site',
      type: 'Site',
      show: true,
    },
    {
      id: 2,
      name: 'Serial Number',
      type: 'SRNumber',
      show: true,
    },
    {
      id: 3,
      name: 'User',
      type: 'User',
      show: true,
    },
  ],

  orderType: {
    'full-load': 'Full Load',
    'full_load': 'Full Load',
    'part-load-door': 'Part Load Doorstep',
    'part-load-office': 'Part Load Office',
  }
};
