import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { CommonService, LoginService } from '@bst-frontend/shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private common: CommonService, private auth: LoginService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree | boolean | UrlTree {
    const isAuthenticated = this.common.isLoggedIn;
    if (!isAuthenticated) {
      if (!state.url.includes('/login')) {
        this.common.navigateByUrl('/login'); // redirect to login
      } else {
        return true;
      }
    } else {
      if (state.url == '/login') {
        this.common.navigateByUrl('/dashboard'); // redirect to dashboard
        return false;
      }
    }
    return isAuthenticated;
  }

  canDeactivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree | boolean | UrlTree {
    if (this.common.isLoggedIn) {
      return true;
    } else {
      this.common.navigateByUrl('dashboard'); // redirect to login
      return false;
    }
  }
}
