<div class="bst-modal">
  <div class="">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="Add-new-addressLabel">{{ title }}</h1>
        <button
          type="button"
          class="btn-close"
          (click)="closeModal(true)"
        ></button>
      </div>
      <div class="modal-body">
        <ng-container [ngTemplateOutlet]="modalContent"></ng-container>
      </div>
      <div class="modal-footer justify-content-between">
        <div class="moda-left-btn d-flex">
          <button type="button" class="btn btn-primary" (click)="onSubmit()">
            {{saveButtonText}}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="closeModal(true)"
          >
            {{cancelButtonText}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
