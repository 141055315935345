<bst-frontend-admin-sidebar></bst-frontend-admin-sidebar>
<!--project start here-->
<div class="container-wide">
<bst-frontend-header></bst-frontend-header>
  <div class="warpper project">
    <div class="container">
      <div class="row">
        <!-- card-->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<script src="js/vendor/jquery-3.6.0.js"></script>
<script type="text/javascript" src="https://cdn.datatables.net/1.13.4/js/jquery.dataTables.min.js"></script>
<script src="https://cdn.datatables.net/rowreorder/1.3.3/js/dataTables.rowReorder.min.js"></script>
<script src="https://cdn.datatables.net/responsive/2.4.1/js/dataTables.responsive.min.js"></script>
<script src="js/vendor/bootstrap.bundle.min.js"></script>
<!-- <script src="js/vendor/perfect-scrollbar.min.js"></script> -->
