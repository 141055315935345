import { Component , Input, ViewChild , ElementRef, Output} from '@angular/core';
import { CommonService , PagesService , CONSTANT } from '@bst-frontend/shared';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'bst-frontend-deletemodal',
  templateUrl: './deletemodal.component.html',
  styleUrls: ['./deletemodal.component.css'],
})
export class DeletemodalComponent {

  @Input() type!: string;
  @Input() id = '';
  @Input() name = '';
  @Input() title = '';
  @Input() created = '';
  @Input() disable = false;
  @Input() deleteEvent = false;
  @Output() deleteEmitter = new Subject<any>();
  //errorModal
  projects = CONSTANT.project;
  reports = CONSTANT.reports;
  showError = false;
  errorMsg: any;
  @ViewChild('errormsg') error!: ElementRef;
  @ViewChild('projecterrormsg') projecterror!: ElementRef;
  constructor(
    private pageService: PagesService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private common: CommonService
  ) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  deleteData() {
    if(this.deleteEvent) {
     this.deleteEmitter.next(true);
     return this.modalService.dismissAll();
    }
    this.pageService.deleteData(this.type, this.id).subscribe({
      next: () => {
        // let value = 1;
        this.pageService.dataChange.next(this.type);
        this.common.popToast(
          'success',
          'Success',
          `${this.title} deleted successfully.`
        );
        this.modalService.dismissAll();
      },
      error: (err) => {
        if (err.status == 400) {
          this.showError = true;
          this.errorMsg = err;
          // let modal;
          // if (this.type === this.project) {
          //   modal = this.projecterror;
          // } else {
          //   modal = this.error;
          // }
          // this.modalService.open(modal, {
          //   // windowClass: 'complete-inspection',
          //   centered: true,
          //   animation: true,
          // });
        }
      },
    });
  }

  open(content: any) {
    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      animation: true,
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  forceDelete() {
    this.pageService.forceDelete(this.type, this.id).subscribe({
      next: () => {
        // let value = 1;
        this.pageService.dataChange.next(this.type);
        this.common.popToast(
          'success',
          'Success',
          `${this.title} deleted successfully.`
        );
        this.modalService.dismissAll();
      },
    });
  }
}
