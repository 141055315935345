import { Component , Input } from '@angular/core';

@Component({
  selector: 'bst-frontend-form-field-error-display',
  templateUrl: './form-field-error-display.component.html',
  styleUrls: ['./form-field-error-display.component.css'],
})
export class FormFieldErrorDisplayComponent {

  @Input() errorMsg!: string;

}
