import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { CommonService } from '../../common/common.service';
import { Router } from '@angular/router';
import { LoginService } from '../api/login.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private common: CommonService,
    private authService: LoginService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = "Something isn't quite right!";
        if (
          (error.status == 400 && request.method == 'DELETE') ||
          (error.status == 422 && this.router.url.includes('change-password'))
        ) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        } else {
          if (error.status == 401 && !this.router.url.includes('login') && !error.url?.includes('logout')) {
            this.authService.logout(false);
          }
          if (error.error.message) {
            errorMessage = error.error.message;
          }
          this.common.popToast('error', 'Error', errorMessage);
        }
        return throwError(error);
      })
    );
  }
}
