import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bst-frontend-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.css'],
})
export class CommonModalComponent implements OnInit {
  @Input() title = '';
  @Input() saveButtonText = 'Save';
  @Input() cancelButtonText = 'Cancel';
  @Input() modalContent!: TemplateRef<any>;
  @Input() modalForm!: FormGroup;
  @Input() submitResponse: any = true;
  @ViewChild('content', { static: true }) modal!: ElementRef;
  @Output() submitEvent = new EventEmitter<boolean>();
  @Output() closeEvent = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal, config: NgbModalConfig) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    console.log('Common modal component initialized', this.modalContent);
  }
  closeModal(sendEvent = false) {
    this.activeModal.close();
    this.closeEvent.emit(true);
  }

  onSubmit() {
    this.submitEvent.emit(this.submitResponse);
  }
}
