import { Component, Input, OnDestroy, Output } from '@angular/core';

import { CommonService } from '@bst-frontend/shared';
import { Subject } from 'rxjs';

@Component({
  selector: 'bst-frontend-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.css'],
})
export class ActionButtonComponent implements OnDestroy {
  isVisible = true;
  @Output() emitter = new Subject<any>();
  @Output() deleteEmitter = new Subject<any>();
  @Input() data!: any;
  @Input() id = '';
  @Input() name = '';
  @Input() actions!: any;
  @Input() title = '';
  @Input() deleteEvent = false;

  constructor(private common: CommonService) {
    this.isVisible = this.common.roleIsManager();
  }

  onActionClick(cmd: string): void {
    this.emitter.next({
      cmd: cmd,
      id: this.id,
      name: this.name,
      data: this.data,
    });
  }

  deleteEmitterEvent(event: any){
    console.log('delete emitter')
    if(event)
    this.deleteEmitter.next({delete: true, id: this.id})
  }

  ngOnDestroy(): void {
    this.emitter.unsubscribe();
  }
}
