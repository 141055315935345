import { INotyfOptions } from 'notyf';

export const NOTYF_OPTIONS: Partial<INotyfOptions> = {
  duration: 3000,
  ripple: true,
  position: { x: 'right', y: 'top' },
  dismissible: true,
  types: [
    {
      type: 'warning',
      background: 'orange',
    },
    {
      type: 'info',
      background: 'gray',
    },
  ],
};
