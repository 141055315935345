import { Injectable, Injector } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../api/login.service';
import { OrderService } from '../api/order.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authService = this.injector.get(LoginService);
    const tokenReq = request.clone({
      // headers: request.headers.set('Authorization', authService.getToken()),
      headers: new HttpHeaders({
        // Accept: 'application/json',
        Authorization: 'Bearer ' + authService.getUserToken(),
      }),
    });
    return next.handle(tokenReq);
  }
}
