<ng-template #content let-modal>
  <div class="modal-content modal-project">
    <div class="modal-header">
      <h5 class="modal-title" id="staticBackdropLabel">Delete  Confirmation</h5>
      <!-- <h5 class="modal-title" id="staticBackdropLabel">Remove {{ title }} ?</h5> -->
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="closeModal()"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.6979 8L14 12.3021L12.3021 14L8 9.6979L3.6979 14L2 12.3021L6.3021 8L2 3.6979L3.6979 2L8 6.3021L12.3021 2L14 3.6979L9.6979 8Z"
            fill="#00B0FE"
          />
        </svg>
      </button>
    </div>
    <div class="modal-body">
      <div class="modal-machine-detail">
        <h5>Are you sure you want to delete this?</h5>
        <!-- <h5>{{ name }}</h5> -->
        <!-- <p>ID: {{ id }}</p> -->
      </div>
      <div class="popup-error-message" *ngIf="showError">
        <div class="error-message">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.25 26.25H28.75L15 2.5L1.25 26.25ZM16.25 22.5H13.75V20H16.25V22.5ZM16.25 17.5H13.75V12.5H16.25V17.5Z"
              fill="#E74C3C"
            />
          </svg>
          <p><span *ngIf="type === projects">Are you sure you want to delete?</span>{{ errorMsg.error.message }}</p>

        </div>
        <div class="assined-user-list" *ngIf="type === projects">
          <div class="user-header">
            <p>Assigned users:</p>
            <p>
              Users:<span> {{ errorMsg.error.users.length }}</span>
            </p>
          </div>
          <ul>
            <li *ngFor="let user of errorMsg.error.users">
              {{ user.name
              }}<span>Department: {{ user.departmentId?.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary btn-blue edit-btn"
        data-bs-dismiss="modal"
        (click)="closeModal()"
      >
        {{ showError && type !== projects ? 'Ok' : 'No' }}
      </button>
      <button
        *ngIf="!showError"
        type="button"
        class="btn btn-primary"
        ngbAutofocus
        (click)="deleteData()"
      >
        Yes
      </button>

      <button
        *ngIf="showError && type === projects"
        type="button"
        class="btn btn-primary"
        (click)="forceDelete()"
      >
        Delete
      </button>
    </div>
  </div>
</ng-template>
<!--  class="disabled-link" -->
<a
  (click)="open(content)"
  title="Delete"
  [ngClass]="disable ? 'disabled-link' : ''"
>
  <svg
    class="deleteModal"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 14.25C4.5 15.075 5.175 15.75 6 15.75H12C12.825 15.75 13.5 15.075 13.5 14.25V5.25H4.5V14.25ZM14.25 3H11.625L10.875 2.25H7.125L6.375 3H3.75V4.5H14.25V3Z"
      fill="#E74C3C"
    />
  </svg>
</a>

<!--Error message for projects-->
<!-- <ng-template #projecterrormsg let-dis="dismiss">
  <div class="modal-content">
    <div class="modal-body text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#3d628a"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
        />
      </svg>
      <p class="alert alert-danger">{{ errorMsg }}</p>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary edit-btn"
      data-bs-dismiss="modal"
      (click)="closeModal()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary btn-blue edit-btn"
      data-bs-dismiss="modal"
      (click)="forceDelete()"
    >
      Delete
    </button>
  </div>
</ng-template> -->

<!--Error message for other modules-->
<!-- <ng-template #errormsg let-dis="dismiss">
  <div class="modal-content">
    <div class="modal-body text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#3d628a"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
        />
      </svg>
      <p class="alert alert-danger">{{ errorMsg }}</p>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary btn-blue edit-btn"
      data-bs-dismiss="modal"
      (click)="closeModal()"
    >
      Ok
    </button>
  </div>
</ng-template> -->
