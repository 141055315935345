<div class="filterbar" *ngIf="filterColumns">
  <form [formGroup]="parentForm">
    <div class="filter_heading">
      <h4>Filter Option</h4>
      <button
        type="button"
        class="btn-close close_filter"
        (click)="closeFilter()"
      ></button>
    </div>
    <div class="filter_options">
      <ng-container *ngFor="let filter of filterColumns">
        <div class="single_filter" *ngIf="filter.show">
          <label> {{ filter?.name }}</label>
          <div class="form-group">
            <ng-select
              class="js-example-basic-single"
              id="invitee"
              formControlName="{{ filter?.formname }}"
              placeholder="Select {{ filter?.name }}"
              [multiple]="true"
            >
              <ng-option *ngFor="let data of filter?.data" [value]="data?._id"
                >{{ data?.name }}
              </ng-option>
            </ng-select>
          </div>
        </div>
      </ng-container>
      <div
        class="single_filter date_filter"
        *ngIf="dataSourceKey === 'reports'"
      >
        <label>From</label>
        <div class="form-group">
          <input
            type="date"
            class="js-example-basic-single"
            id="invitee"
            formControlName="startDate"
            placeholder="Select date"
          />
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="this.parentForm.controls['startDate'].errors?.['required']"
          >
            <div>Start Date is required.</div>
          </div>
        </div>
        <label>To</label>
        <div class="form-group">
          <input
            type="date"
            class="js-example-basic-single"
            id="invitee"
            formControlName="endDate"
            placeholder="Select date"
            [min]="currentDate"
          />
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="this.parentForm.controls['endDate'].errors?.['required']"
          >
            <div>End Date is required.</div>
          </div>
        </div>
      </div>
      <div
        class="single_filter date_filter"
        *ngIf="dataSourceKey === 'actions'"
      >
        <label> Created From</label>
        <div class="form-group">
          <input
            type="date"
            class="js-example-basic-single"
            id="invitee"
            formControlName="createdFrom"
            placeholder="Select date"
          />
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="this.parentForm.controls['createdFrom'].errors?.['required']"
          >
            <div>CreateFrom is required.</div>
          </div>
        </div>
        <label>Created To</label>
        <div class="form-group">
          <input
            type="date"
            class="js-example-basic-single"
            id="invitee"
            formControlName="createdTo"
            placeholder="Select date"
            [min]="createFromDate"
            [required]="parentForm.get('createdFrom')?.value !== null"
          />
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="this.parentForm.controls['createdTo'].errors?.['required']"
          >
            <div>createdTo is required.</div>
          </div>
        </div>
        <label>Due Date From</label>
        <div class="form-group">
          <input
            type="date"
            class="js-example-basic-single"
            id="invitee"
            formControlName="dueFrom"
            placeholder="Select date"
          />
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="this.parentForm.controls['dueFrom'].errors?.['required']"
          >
            <div>dueFrom Date is required.</div>
          </div>
        </div>
        <label>Due Date To</label>
        <div class="form-group">
          <input
            type="date"
            class="js-example-basic-single"
            id="invitee"
            formControlName="dueTo"
            placeholder="Select date"
            [min]="dueDate"
            [required]="parentForm.get('dueFrom')?.value !== null"
          />
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="this.parentForm.controls['dueTo'].errors?.['required']"
          >
            <div>dueTo is required.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="filter_action">
      <a class="btn btn-primary btn-blue" (click)="clearFilter()">Clear</a>
      <a class="btn btn-primary" (click)="applyFilter()">Apply</a>
    </div>
  </form>
</div>
<table
  *ngIf="dtOptions"
  datatable
  [dtOptions]="dtOptions"
  [dtTrigger]="dtTrigger"
  [ngClass]="dataClass"
  class="nowrap"
  style="width: 100%"
></table>
