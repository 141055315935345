<ng-container *ngIf="actions">
  <ul class="actions">
    <li *ngIf="actions.user">
      <a
        title="View User"
        [class.disabled-link]="actions.user.disable"
        (click)="onActionClick(actions.user.cmd)"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z"
            fill="#025C54"
          />
        </svg>
      </a>
    </li>
    <li *ngIf="actions.view">
      <a
        title="view"
        [class.disabled-link]="actions.view.disable"
        (click)="onActionClick(actions.view.cmd)"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99998 3.75C5.83331 3.75 2.27498 6.34167 0.833313 10C2.27498 13.6583 5.83331 16.25 9.99998 16.25C14.1666 16.25 17.725 13.6583 19.1666 10C17.725 6.34167 14.1666 3.75 9.99998 3.75ZM9.99998 14.1667C7.69998 14.1667 5.83331 12.3 5.83331 10C5.83331 7.7 7.69998 5.83333 9.99998 5.83333C12.3 5.83333 14.1666 7.7 14.1666 10C14.1666 12.3 12.3 14.1667 9.99998 14.1667ZM9.99998 7.5C8.61665 7.5 7.49998 8.61667 7.49998 10C7.49998 11.3833 8.61665 12.5 9.99998 12.5C11.3833 12.5 12.5 11.3833 12.5 10C12.5 8.61667 11.3833 7.5 9.99998 7.5Z"
            fill="#025C54"
          />
        </svg>
      </a>
    </li>
    <li *ngIf="actions.edit && !isVisible">
      <a
        title="Edit"
        [class.disabled-link]="actions.edit.disable"
        (click)="onActionClick(actions.edit.cmd)"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 14.375V17.5H5.625L14.8417 8.28334L11.7167 5.15834L2.5 14.375ZM17.2583 5.86667C17.5833 5.54167 17.5833 5.01667 17.2583 4.69167L15.3083 2.74167C14.9833 2.41667 14.4583 2.41667 14.1333 2.74167L12.6083 4.26667L15.7333 7.39167L17.2583 5.86667Z"
            fill="#025C54"
          />
        </svg>
      </a>
    </li>
    <li *ngIf="actions.clone && !isVisible">
      <a
        title="Duplicate"
        [class.disabled-link]="actions.clone.disable"
        (click)="onActionClick(actions.clone.cmd)"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1614_2633)">
            <path
              d="M10.6667 0.666016H2.66671C1.93337 0.666016 1.33337 1.26602 1.33337 1.99935V11.3327H2.66671V1.99935H10.6667V0.666016ZM10 3.33268L14 7.33268V13.9993C14 14.7327 13.4 15.3327 12.6667 15.3327H5.32671C4.59337 15.3327 4.00004 14.7327 4.00004 13.9993L4.00671 4.66602C4.00671 3.93268 4.60004 3.33268 5.33337 3.33268H10ZM9.33337 7.99935H13L9.33337 4.33268V7.99935Z"
              fill="#00B0FE"
            />
          </g>
          <defs>
            <clipPath id="clip0_1614_2633">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </a>
    </li>
    <li *ngIf="actions.delete">
      <bst-frontend-deletemodal
        *ngIf="!isVisible"
        [type]="actions.delete.page"
        [id]="id"
        [name]="name"
        [title]="title"
        [disable]="actions.delete.disable"
        [deleteEvent]="deleteEvent"
        (deleteEmitter)="deleteEmitterEvent($event)"
      ></bst-frontend-deletemodal>
    </li>
    <li *ngIf="actions.login && !isVisible">
      <a
        title="Login to Company Admin"
        (click)="onActionClick(actions.login.cmd)"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z"
            fill="white"
          />
        </svg>
      </a>
    </li>
  </ul>
</ng-container>
