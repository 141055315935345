import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';



@Injectable({
  providedIn: 'root'
})
export class RealtimeOrderUpdateService {

  constructor(private db: AngularFireDatabase) { }

  getPresence() {
    return this.db.object('orders').snapshotChanges();
  }

  removeOrder(orderId: string){
    return this.db.object(`orders/${orderId}`).remove();
  }

  getDBRef(){
    return  this.db.list('orders');
  }

  getAddedOrder(){
    return  this.getDBRef().stateChanges(['child_added']);
  }

  getRemovedOrder(){
    return  this.getDBRef().stateChanges(['child_removed']);
  }
}
