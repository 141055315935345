import { Component, OnInit, Inject } from '@angular/core';
import { CommonService, LoginService } from '@bst-frontend/shared'


@Component({
  selector: 'bst-frontend-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  name = "Admin";
  role = "Admin";
  loginUser:any = {};

  constructor(
    private auth: LoginService,
    private common: CommonService,
    @Inject('environment') private environment: any
  ) { }

  ngOnInit(): void {
    this.loginUser = this.common.getUserData();
    if (this.loginUser) {
      this.name = (this.loginUser?.name) || 'Admin';
      this.role = (this.loginUser?.role) || 'User';
    }
  }

  logout() {
    this.auth.logout();
  }
}

