import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Notyf, NotyfNotification } from 'notyf';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NOTYF_OPTIONS } from '../constants/notyf';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public isLoggedUser = false;
  public isLoading = new BehaviorSubject<boolean>(false);
  public checklistLoader = new BehaviorSubject<boolean>(false);
  toastr!: Notyf;
  notification!: NotyfNotification;

  get isLoggedIn(): boolean {
    const authToken = localStorage.getItem('token' || '');
    if (authToken) {
      return true;
    }
    return false;
  }
  constructor(
    private router: Router,
    @Inject('environment') private environment: any,
    private http: HttpClient
  ) {
    this.toastr = new Notyf(NOTYF_OPTIONS);
  }

  getdashboardApi(id = '') {
    let options = {};
    if (id != '') {
      let params = new HttpParams();
      params = params.append('companyId', id);
      options = {
        params: params,
      };
    }
    return this.http.get(
      `${this.environment.apiUrl}account/dashboard`,
      options
    );
  }

  storeData(key: { [key: string]: string }, storage?: Storage) {
    try {
      storage = storage || localStorage;
      for (const k in key) {
        storage.setItem(k, key[k]);
      }
    } catch (e: any) {
      if (!this.environment.production) {
        console.error('error', e);
      }
    }
  }

  removeData(key: string[]) {
    key.forEach((element) => {
      return localStorage.removeItem(element);
    });
  }

  //function for get any data from LocalStorage
  getLocalData(name: string) {
    return localStorage.getItem(name) || '';
  }

  //function for get any data from LocalStorage
  getUserData() {
    let user: any = localStorage.getItem('loginData') || '';
    if (user) {
      user = JSON.parse(user);
      return user.data;
    }
  }

  navigateByUrl(url: string) {
    return this.router.navigateByUrl(`${url}`);
  }

  navigateByUrlWithParams(
    url: string,
    params: any = null,
    queryParamsHandling: any = 'merge'
  ) {
    return this.router.navigate([url], {
      queryParams: { ...params },
      queryParamsHandling: queryParamsHandling,
    });
  }

  async getDeviceData() {
    const data = {
      fcmToken: localStorage.getItem('FCM_token') || '',
      deviceId: localStorage.getItem('device_token') || '',
      deviceType: this.environment.deviceType || 'web',
    };

    return data;
  }

  addCommonDeviceData() {
    this.storeData({
      device_token: this.deviceIdGenerator(),
    });
  }

  popToast(type: 'error' | 'success' | 'warning', title: string, body: string) {
    // this.toastr.dismiss(this.notification);
    this.notification = this.toastr.open({ type: type, message: body });
  }

  disableRightClick() {
    document.addEventListener('contextmenu', (event: any) => {
      if (
        (event && event.target && event.target.nodeName == 'IMG') ||
        event.target.nodeName == 'VIDEO'
      ) {
        event.preventDefault();
      }
    });
  }

  getModulePermission(ModuleName: string) {
    const userData = this.getUserData();
    const modulesArray = userData.permissions?.modules;
    if (modulesArray && modulesArray.length > 0) {
      return modulesArray.includes(ModuleName) ? true : false;
    }
    return false;
  }

  getRole() {
    const userData = this.getUserData();
    return userData.role;
  }

  isRole(role: string) {
    return this.getUserData().role === role;
  }

  roleIsManager() {
    return this.getUserData().role === 'Management';
  }

  roleIsSJOManager() {
    return this.getUserData().role === 'SJOManager';
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          console.log(
            'Latitude: ' +
              position.coords.latitude +
              'Longitude: ' +
              position.coords.longitude
          );
        }
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }

  startLoader() {
    this.isLoading.next(true);
  }

  stopLoader() {
    this.isLoading.next(false);
  }

  //FILTER
  getFilterData(page: string) {
    return this.http.post(`${this.environment.apiUrl}${page}/filter-data`, '');
  }

  //function for generating device ID
  deviceIdGenerator() {
    const chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 10; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  removeEmptyObjectsForm(form: FormGroup | any) {
    Object.keys(form).forEach((k) => {
      if (form[k] === null || form[k] === undefined || form[k].length == 0) {
        delete form[k];
      }
    });
    return form;
  }

  scrollEffect() {
    const body = document.body;
    // const header: any = document.querySelector('.checklist-header');
    // const scrollUp = 'scroll-up';
    const scrollDown = 'scroll-down';
    let lastScroll = 0;

    window.addEventListener('scroll', () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        // body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
        // lottiePlayer.play();
      } else if (
        currentScroll < lastScroll &&
        body.classList.contains(scrollDown)
      ) {
        // up
        body.classList.remove(scrollDown);
        // body.classList.add(scrollUp);
        // lottiePlayer.stop();
      }
      lastScroll = currentScroll;
    });
  }

  isInteger(value: any): value is number {
    return (
      typeof value === 'number' &&
      isFinite(value) &&
      Math.floor(value) === value
    );
  }

  toDateString(
    date: NgbDateStruct | null,
    time: NgbTimeStruct | null,
    UTC = false
  ): string {
    let year, month, day, hour, minute, second;
    const today = new Date();
    if (
      this.isInteger(date?.year) &&
      this.isInteger(date?.month) &&
      this.isInteger(date?.day)
    ) {
      year = date?.year.toString().padStart(2, '0');
      month = date?.month.toString().padStart(2, '0');
      day = date?.day.toString().padStart(2, '0');
    } else {
      year = today.getFullYear().toString().padStart(2, '0');
      month = (today.getMonth() + 1).toString().padStart(2, '0');
      day = today.getDate().toString().padStart(2, '0');
    }

    if (
      this.isInteger(time?.hour) &&
      this.isInteger(time?.minute) &&
      this.isInteger(time?.second)
    ) {
      hour = time?.hour.toString().padStart(2, '0');
      minute = time?.minute.toString().padStart(2, '0');
      second = time?.second.toString().padStart(2, '0');
    } else {
      hour = '00';
      minute = '00';
      second = '00';
    }
    let dif = '+',
      tzo = 0;
    const pad = (num: any) => {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
    if (!UTC) {
      const timeZoneOffset = new Date().getTimezoneOffset();
      tzo = -timeZoneOffset;
      dif = tzo >= 0 ? '+' : '-';
    }

    const isoString = `${pad(year)}-${pad(month)}-${pad(day)}T${pad(
      hour
    )}:${pad(minute)}:${pad(second)}${dif}${pad(tzo / 60)}:${pad(tzo % 60)}`;
    return isoString;
  }

  // get UUID from API
  getUUID() {
    return this.http.get(`${this.environment.apiUrl}account/get-uuid`);
  }

  // set dataTable pagination page numbers
  setPaginationNumbers(dtElement: any) {
    dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      document
        .querySelector('.dataTables_paginate.paging_full_numbers')
        ?.classList.add('justify-content-center');
      const pageNumbers = document.querySelectorAll(
        '.dataTables_paginate.paging_full_numbers span a.paginate_button'
      );
      pageNumbers.forEach((e, key) => {
        if (key < 2 || pageNumbers.length - 1 == key) {
          return;
        }
        e.remove();
      });
      const length = dtInstance.page.info().pages;
      if (length < 8 && length > 3) {
        const span = document.createElement('span');
        span.classList.add('ellipsis');
        span.innerHTML = '…';
        pageNumbers[1].after(span);
      }
    });
  }
}
