import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppSettingService {

  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient
  ) {

  }

  getAppSetting(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}app-setting`);
  }

  saveAppSetting(data: any) {
    return this.http.post(`${this.environment.apiUrl}app-setting`, data);
  }
}
