import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { CommonService } from '../../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private token!: string;
  private isUser = false;
  private userStatus = new BehaviorSubject<boolean>(false);
  private userId!: string;
  private StorageData: any = {};

  public static isAuthenticated(): boolean {
    const token = JSON.parse(localStorage.getItem('loginData') || '{}');
    if (token) {
      return true;
    }
    return false;
  }

  constructor(
    private http: HttpClient,
    private common: CommonService,
    @Inject('environment') private environment: any
  ) {}

  getToken() {
    if (localStorage.getItem('token') != null) return this.token;
    return '';
  }

  getUserToken() {
    this.StorageData = JSON.parse(localStorage.getItem('loginData') || '{}');
    if (!this.StorageData.data) {
      return null;
    }
    return this.StorageData.token;
  }

  getIsUser() {
    return this.isUser;
  }

  getUserId() {
    return this.userId;
  }

  getUserAuthentication() {
    return this.userStatus.asObservable();
  }

  async login(data: { email: string; password: string }) {
    data = {
      ...data,
      // ...(await this.common.getDeviceData()),
    };
    console.log(this.environment.apiUrl);
    this.http
      .post(`${this.environment.apiUrl}account/login`, data)
      .subscribe(async (response: any) => {
        await this.storeDataInLocal(response);
        this.common.navigateByUrl('/').then(() => {
          this.common.popToast('success', 'Success', 'Successfully logged in.');
        });
      });
  }

  storeDataInLocal(response: any) {
    const token = response.accessToken;
    this.token = token;
    if (token) {
      this.isUser = true;
      this.userId = response.userId;
      this.StorageData = {
        data: response,
        token: response.accessToken,
        role: response.role,
      };
      this.userStatus.next(true);
      this.common.storeData({
        loginData: JSON.stringify(this.StorageData),
        token: this.StorageData.token,
      });
    }
  }

  async forgotPassword(data: any) {
    this.http
      .post(`${this.environment.apiUrl}account/forgot-password`, data)
      .subscribe((response: any) => {
        if (response && response.message) {
          this.common.navigateByUrl(`login/reset/${data.email}`).then(() => {
            this.common.popToast('success', 'Success', response.message);
          });
        }
      });
  }

  async resetPassword(data: { email: string; password: string }) {
    this.http
      .post(`${this.environment.apiUrl}account/reset-password`, data)
      .subscribe((response: any) => {
        if (response) {
          if (response.message) {
            this.common.navigateByUrl('/login').then(() => {
              this.common.popToast(
                'success',
                'Success',
                response.message || 'Password reset Successfully!'
              );
            });
          }
        }
      });
  }

  async loginWithOtp(data: any) {
    data = {
      ...data,
      ...(await this.common.getDeviceData()),
    };
    this.http
      .post(`${this.environment.apiUrl}account/login`, data)
      .subscribe((response: any) => {
        if (response) {
          this.common.navigateByUrl(`/login/otp/${data.mobileNo}`).then(() => {
            this.common.popToast('success', 'Success', response.message);
          });
        }
      });
  }

  async verifyOtp(data: any) {
    data = {
      ...data,
      ...(await this.common.getDeviceData()),
    };
    this.http
      .post(`${this.environment.apiUrl}account/login-otp-verify`, data)
      .subscribe((response: any) => {
        const token = response.accessToken;
        this.token = token;
        if (token) {
          this.isUser = true;
          this.userId = response.userId;
          this.StorageData = {
            data: response,
            token: response.accessToken,
          };
          this.userStatus.next(true);
          this.common.storeData({
            loginData: JSON.stringify(this.StorageData),
            token: this.StorageData.token,
          });
          this.common.navigateByUrl('dashboard').then(() => {
            this.common.popToast(
              'success',
              'Success',
              'Successfully logged in.'
            );
          });
        }
      });
  }

  updatePassword(data: {
    oldPassword: string;
    newPassword: string;
  }): Observable<any> {
    return this.http.post(
      `${this.environment.apiUrl}account/change-password`,
      data
    );
  }

  autoAuthUser() {
    localStorage.getItem('token');
    if (localStorage.getItem('token')) {
      this.isUser = true;
      this.userStatus.next(true);
    }
  }

  async logout(flag = true) {
    const data = await this.common.getDeviceData();
    this.http.post(`${this.environment.apiUrl}account/logout`, data).subscribe({
      next: (resp: any) => {
        this.clearLoginData(flag);
      },
      error: (err: any) => {
        if (err.status == 401) {
          this.clearLoginData(flag);
        }
        // console.log(err);
      },
    });
  }

  private clearLoginData(flag: boolean) {
    localStorage.clear();
    this.userStatus.next(false);
    this.common.navigateByUrl('login').then(() => {
      flag &&
        this.common.popToast('success', 'Success', 'Successfully logged Out.');
    });
  }

  accountDeletion(data: any) {
    return this.http.post(
      `${this.environment.apiUrl}account/account-deletion`,
      data
    );
  }
}
