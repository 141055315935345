import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { PagesService, CommonService } from '@bst-frontend/shared';

@Component({
  selector: 'bst-frontend-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css'],
})
export class DatatableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() dataConfig = [];
  @Input() dataSourceKey = '';
  @Input() dataClass = '';
  @Input() selectedItem: any = [];
  @Input() dataUrl = '';
  @Input() dataUrlParam = '';
  @Input() buttonClick = false;
  @Input() dataBackendUrlParam: any = {};
  @Input() dataRender: Subject<string> = new Subject<string>();
  @Output() sendSelectedItem = new EventEmitter<string[]>();
  @Output() sendDataCount = new EventEmitter<string | number>();

  //Param URL
  @Input() hasParam = false;
  @Input() paramData: any;

  //FILTER
  filterbutton: any;
  // filter = false;
  parentForm!: FormGroup;
  filterParams: any;
  customParams: any;
  @Input() filterColumns: any;
  @Input() customFilters: any;
  @Input() filter = false;
  @Input() dateTimeColumn = false;
  currentDate = '';
  createFromDate: any;
  dueDate: any;

  recordsFiltered = 0;
  recordsTotal = 0;
  dtOptions: any = {};
  dtTrigger: Subject<object> = new Subject<object>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  constructor(
    private pageService: PagesService,
    private common: CommonService,
    private fb: FormBuilder,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.currentDate = new Date().toISOString().slice(0, 10);
  }

  ngOnInit(): void {
    this.document.body.classList.add('is-filter');
    this.parentForm = this.initform();
    this.dtOptions = {
      ...this.dataConfig,
      pageLength: 10,
      serverSide: true,
      processing: true,
      autoWidth: true,
      scrollX: true,
      order: [],
      search: { return: true },
      ajax: (params: any, callback: any) => {
        let url = this.dataSourceKey;
        if (params.length) {
          params.page = Math.round(params.start / params.length) + 1;
          if (params.length < 0) {
            params.length = 5;
          }
          if (params.search) {
            this.filter =
              (params.search.value && params.search.value != '') || this.filter
                ? true
                : false;
            if (this.filter) {
              const text = params.search.value;
              if (this.customFilters && this.customFilters?.status) {
                this.filterParams = {
                  ...this.customFilters?.data,
                  searchKey: text,
                };
              } else {
                this.filterParams = {
                  ...this.customParams,
                  searchKey: text,
                };
              }
              url = `${url}`;
            }
          }
        }
        let data;
        if (params.order && params.order.length > 0) {
          const sortOrder = params.order[0].dir == 'asc' ? 1 : -1;
          const index = params.order[0].column;
          const sortField = params.columns[index].name;
          data = {
            sortOrder: sortOrder,
            sortField: sortField,
          };
        }
        if (this.filter) {
          this.pageService
            .customFilter(url, params.page, params.length, this.filterParams)
            .subscribe(
              (resp: any) => {
                callback({
                  recordsTotal: resp.data.total || 0,
                  recordsFiltered: resp.data.total || 0,
                  data: resp.data.docs,
                });
                this.sendDataCount.emit(resp.data.total || 0);
              },
              () => {
                callback({
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: {},
                });
                this.sendDataCount.emit(0);
              }
            );
        } else {
          if (this.hasParam) data = { ...data, ...this.paramData };
          this.pageService
            .getData(url, params.page, params.length, data)
            .subscribe(
              (resp: any) => {
                callback({
                  recordsTotal: resp.data.total || 0,
                  recordsFiltered: resp.data.total || 0,
                  data: resp.data.docs,
                });
                this.sendDataCount.emit(resp.data.total || 0);
              },
              () => {
                callback({
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: {},
                });
                this.sendDataCount.emit(0);
              }
            );
        }
      },
      rowCallback: (row: Node, data: any) => {
        row.addEventListener('click', () => {
          this.getSelectedItem(data);
        });
        if (this.dataUrl) {
          if (this.buttonClick) {
            row.childNodes.forEach((element) => {
              if (element != row.lastChild) {
                element.addEventListener('click', () => {
                  this.setClickEvent(data);
                });
              }
            });
          } else {
            row.addEventListener('click', () => {
              this.setClickEvent(data);
            });
          }
        }
        return row;
      },

      drawCallback: () => {
        this.setSelectedItem();
        setTimeout(() => {
          this.columnAdjust();
        });
      },
      initComplete: () => {
        this.setCustomSearch();
        this.setResizeWindowFunction();
        setTimeout(() => {
          this.columnAdjust();
        });
        this.setFilterBtnEvent();
      },
    };
    if (this.customFilters && !this.customFilters?.status) {
      this.filter = true;
      this.applyFilter();
    }
    this.dataRender.subscribe((value) => {
      // if (value == 'filter') {
      //   this.filter = true;
      // } else {
      //   this.filter = false;
      // }
      this.rerender(true);
    });

    this.addValidators();
  }

  private initform() {
    const group: FormGroup = this.fb.group({});
    this.filterColumns?.forEach((ele: any) => {
      group.addControl(
        ele.formname,
        this.fb.control(ele?.formValue ? ele?.formValue : '')
      );
    });
    if (this.dateTimeColumn) {
      if (this.dataSourceKey === 'reports') {
        group.addControl('startDate', new FormControl());
        group.addControl('endDate', new FormControl());
      } else if (this.dataSourceKey === 'actions') {
        group.addControl('createdFrom', new FormControl());
        group.addControl('createdTo', new FormControl());
        group.addControl('dueFrom', new FormControl());
        group.addControl('dueTo', new FormControl());
      }
    }

    return group;
  }

  addValidators() {
    this.parentForm.get('startDate')?.valueChanges.subscribe((value) => {
      this.parentForm.get('endDate')?.setValidators([Validators.required]);
      this.currentDate = value;
      this.parentForm.get('endDate')?.updateValueAndValidity();
    });
    this.parentForm.get('createdFrom')?.valueChanges.subscribe((value) => {
      if (value) {
        this.parentForm.get('createdTo')?.setValidators([Validators.required]);
        this.createFromDate = value;
      } else {
        this.parentForm.get('createdTo')?.clearValidators();
        this.createFromDate = null;
      }
      this.parentForm.get('createdTo')?.updateValueAndValidity();
    });
    this.parentForm.get('dueFrom')?.valueChanges.subscribe((value) => {
      if (value) {
        this.parentForm.get('dueTo')?.setValidators([Validators.required]);
        this.dueDate = value;
      } else {
        this.parentForm.get('dueTo')?.clearValidators();
        this.dueDate = null;
      }
      this.parentForm.get('dueTo')?.updateValueAndValidity();
    });
  }

  get parentFormControl() {
    return this.parentForm.controls;
  }

  isDirtyOrTouched(name: string) {
    return (
      this.parentForm?.controls[name]?.dirty ||
      this.parentForm?.controls[name]?.touched
    );
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next(this.dtOptions);
    this.pageService.dataChange.subscribe(() => {
      // this.dataSourceKey = value;
      this.dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(this.dtOptions);
      });
      // this.rerender();
    });
  }

  rerender(reset = false): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (reset) {
        dtInstance.destroy();
        this.dtTrigger.next(this.dtOptions);
      } else {
        dtInstance.ajax.reload(() => null, false);
      }
    });
  }

  columnAdjust() {
    this.dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance?.columns?.adjust();
    });
  }

  getSelectedItem(data: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      setTimeout(() => {
        const index = this.selectedItem.findIndex((x: any) => {
          return x && x._id ? x._id == data._id : x == data._id;
        });
        if (index > -1) {
          this.selectedItem.splice(index, 1);
        } else {
          this.selectedItem.push(
            ...dtInstance.rows({ selected: true }).data().toArray()
          );
        }
        this.selectedItem = [
          ...new Map(
            this.selectedItem.map((item: any) => {
              return item && item._id ? [item._id, item] : [item, item];
            })
          ).values(),
        ];
        this.sendSelectedItem.emit(this.selectedItem);
      }, 10);
    });
  }

  setSelectedItem() {
    if (this.selectedItem.length > 0) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        setTimeout(() => {
          dtInstance
            .rows((idx: any, data: any) => {
              let obj: any = '';
              obj = this.selectedItem.find((x: any) => {
                return x && x._id ? x._id == data._id : x == data._id;
              });
              return obj ? true : false;
            })
            .select();
        }, 100);
      });
    }
  }

  setClickEvent(data: any) {
    if (this.dataBackendUrlParam) {
      this.common.navigateByUrlWithParams(
        `${this.dataUrl}/${
          this.dataUrlParam ? data[this.dataUrlParam]._id : data._id
        }`,
        this.dataBackendUrlParam
      );
    } else {
      this.common.navigateByUrl(
        `${this.dataUrl}/${
          this.dataUrlParam ? data[this.dataUrlParam]._id : data._id
        }`
      );
    }
  }

  setCustomSearch() {
    const container = document.querySelectorAll('.dataTables_filter');
    const button = document.createElement('button');
    button.type = 'button';
    button.innerHTML = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" fill="#ffffff"/>
</svg>`;

    button.addEventListener('click', (e: any) => {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        const input = container[0].querySelector('input');
        input && dtInstance.search(input?.value).draw();
      });
    });
    button.className = 'custom_search dt_search';
    // let filterbutton: any;
    if (this.filterColumns?.length > 0) {
      container[0].setAttribute('class', 'dataTables_filter filterbtn');
      this.filterbutton = document.createElement('button');
      this.filterbutton.type = 'button';
      this.filterbutton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><g><path d="M0,0h24 M24,24H0" fill="none"/><path d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"/><path d="M0,0h24v24H0V0z" fill="none"/></g></svg>`;
      this.filterbutton.className = 'filterbar-toggle btn-blue';
      if (this.customFilters && !this.customFilters?.status) {
        this.filterCount(1);
      }
    }
    container.forEach((e) => {
      if (!e.querySelector('.dt_search')) {
        e.append(button);
        if (this.filterColumns?.length > 0) {
          e.append(this.filterbutton);
        }
      }
    });
  }

  setFilterBtnEvent() {
    this.document
      .getElementsByClassName('filterbar-toggle')[0]
      ?.addEventListener('click', () => {
        setTimeout(() => {
          this.columnAdjust();
        }, 200);
      });
  }

  applyFilter() {
    if (this.parentForm.invalid) return this.parentForm.markAllAsTouched();
    const filters = this.parentForm.getRawValue();
    Object.keys(filters).forEach((k) => {
      if (
        filters[k] === null ||
        filters[k] === undefined ||
        filters[k].length == 0
      ) {
        delete filters[k];
      }
    });
    if (!Object.keys(filters).length) return;
    const filterCount = Object.keys(filters).length;
    this.filterCount(filterCount);
    this.customParams = filters;
    this.filter = true;
    // this.isCount = true;
    this.dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.page(0).draw(false);
    });
  }

  filterCount(count: number) {
    if (document.getElementById('count')) {
      const element: any = document.getElementById('count'); // will return element
      element.parentNode.removeChild(element);
    }
    if (count > 0) {
      this.filterbutton &&
        (this.filterbutton.innerHTML += `<span class="badge badge-light" id="count">${count}</span>`);
      // this.isCount = !this.isCount;
    }
  }

  clearFilter() {
    this.filter = false;
    this.customParams = this.parentForm.reset();
    this.filterCount(0);
    this.rerender();
    this.parentForm.get('endDate')?.clearValidators();
    this.parentForm.get('endDate')?.updateValueAndValidity();
  }

  closeFilter() {
    this.document.body.classList.add('is-filter');
    setTimeout(() => {
      this.columnAdjust();
    }, 200);
  }

  setResizeWindowFunction() {
    addEventListener('resize', () => {
      setTimeout(() => {
        this.columnAdjust();
      }, 200);
    });
  }

  ngOnDestroy(): void {
    this.document.body.classList.add('is-filter');
    this.dtTrigger.unsubscribe();
  }
}
