import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(name: string) {
    let shortName = '';
    if (name && name != '') {
      const matches = name.match(/\b(\w)/g);
      const acronym = matches && matches.join('');
      shortName = acronym ? acronym : name;
      shortName = shortName.slice(0, 2).toUpperCase();
    }
    return shortName;
  }
}
