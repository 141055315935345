import { AfterViewInit, Component, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
declare const google: any;

@Component({
  selector: 'bst-frontend-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.css'],
})
export class AddressAutocompleteComponent   implements AfterViewInit{

  @Output() dataEmitter = new EventEmitter();
  @ViewChild('searchAddress') searchElementRef!: ElementRef;
  
  ngAfterViewInit(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
      {
        // types: ['address'], // You can customize types as needed (e.g., 'establishment', 'geocode').
        types: ['establishment'], // You can customize types as needed (e.g., 'establishment', 'geocode').
        // types: ['geocode'], // You can customize types as needed (e.g., 'establishment', 'geocode').
        componentRestrictions: { country: 'in' } // Restrict to a specific country (e.g., 'in' for India).
      }
    );
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      this.dataEmitter.emit(place);
    });
  }
}
