import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminSidebarComponent } from './admin-sidebar/admin-sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from '@bst-frontend/shared';
import { DatatableComponent } from './datatable/datatable.component';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';
import { ActionButtonComponent } from './action-button/action-button.component';
import { DeletemodalComponent } from './deletemodal/deletemodal.component';
import { FormFieldErrorDisplayComponent } from './form-field-error-display/form-field-error-display.component';
import { CommonModalComponent } from './common-modal/common-modal.component';
import { AddressAutocompleteComponent } from './address-autocomplete/address-autocomplete.component'

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    SharedModule,
    DataTablesModule,
  ],
  declarations: [
    AdminSidebarComponent,
    HeaderComponent,
    DatatableComponent,
    ActionButtonComponent,
    DeletemodalComponent,
    FormFieldErrorDisplayComponent,
    CommonModalComponent,
    AddressAutocompleteComponent
  ],
  exports: [
    AdminSidebarComponent,
    HeaderComponent,
    DatatableComponent,
    ActionButtonComponent,
    DeletemodalComponent,
    FormFieldErrorDisplayComponent,
    CommonModalComponent,
    AddressAutocompleteComponent
  ],
})
export class CommonUiModule {}
