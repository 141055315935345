export const environment = {
  production: false,
  appName: 'BST',
  appLongName: 'bst',
  appLogo: '/assets/img/logo.svg',
  appSmallLogo: '/assets/img/logo-sm.svg',
  appFavicon: 'favicon.ico',
  apiUrl: 'https://api.dev.bstm.in/backend/',
  firebaseConfig: {
    apiKey: 'AIzaSyCDuqHRs69E-2wHmuaZYswuY4W9AXXu61o',
    authDomain: 'bst-app-9b2b4.firebaseapp.com',
    databaseURL: 'https://bst-app-9b2b4-default-rtdb.firebaseio.com',
    projectId: 'bst-app-9b2b4',
    storageBucket: 'bst-app-9b2b4.appspot.com',
    messagingSenderId: '85017163255',
    appId: '1:85017163255:web:ef69415ee58cf8c5236af2',
    measurementId: 'G-DFBYSH1L95',
  },
};
