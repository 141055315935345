import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  dataChange: Subject<string> = new Subject<string>();
  change = 1;
  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient
  ) { }

  getData(
    page: string,
    currentPage: number,
    itemsPerpage: number,
    data: any = ''
  ): Observable<any> {
    return this.http.get(
      `${this.environment.apiUrl}${page}/${currentPage}/${itemsPerpage}`,
      { params: data }
    );
  }

  getPageData(page: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}${page}`);
  }
  getSearchData(
    page: string,
    currentPage: number,
    itemsPerpage: number,
    data: string
  ) {
    return this.http.post(
      `${this.environment.apiUrl}${page}/search/${currentPage}/${itemsPerpage}`,
      {
        searchKey: data,
      }
    );
  }

  addData(page: string, data: any) {
    return this.http.post(`${this.environment.apiUrl}${page}/`, data);
  }

  updateData(page: string, data: any, id: string) {
    return this.http.patch(`${this.environment.apiUrl}${page}/${id}`, data);
  }

  deleteData(type: string, id: string) {
    return this.http.delete(`${this.environment.apiUrl}${type}/${id}`);
  }

  forceDelete(type: string, id: string) {
    return this.http.delete(
      `${this.environment.apiUrl}${type}/${id}/force-delete`
    );
  }

  customFilter(
    page: string,
    currentPage: number,
    itemsPerpage: number,
    filterData: any,
    data: any = ''
  ) {
    return this.http.get(
      `${this.environment.apiUrl}${page}/${currentPage}/${itemsPerpage}?searchKey=${filterData?.searchKey}`
    );
  }
  // customFilter(
  //   page: string,
  //   currentPage: number,
  //   itemsPerpage: number,
  //   filterData: string,
  //   data: any = ''
  // ) {
  //   return this.http.post(
  //     `${this.environment.apiUrl}${page}/${currentPage}/${itemsPerpage}`,
  //     filterData
  //   );
  // }
}
