import { Component } from '@angular/core';
import { RealtimeOrderUpdateService } from '@bst-frontend/shared';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'bst-frontend-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.css'],
})
export class AdminSidebarComponent {
  orderCount = 0;
  initialOrderCount = 0;
  constructor(private realtimeOrderUpdateService: RealtimeOrderUpdateService, private router: Router) {
    this.realtimeOrderUpdateService.getDBRef().snapshotChanges().subscribe(snapData => {
      this.initialOrderCount = snapData.length;
      this.realtimeOrderUpdateService.getRemovedOrder().subscribe(actions => {
        --this.orderCount;
      });
      this.realtimeOrderUpdateService.getAddedOrder().subscribe(actions => {
        ++this.orderCount;
        this.reloadOrderPage();
      })
    });
  }

  reloadOrderPage() {
    if (this.router.url == '/order' && (this.orderCount > this.initialOrderCount)) {
      location.reload();
    }
  }
}
