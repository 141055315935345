export const VALIDATION_MESSAGES = {
  email: {
    required: 'Email is required',
    email: 'This email is invalid'
  },
  password: {
    required: 'Password is required',
    minlength: 'The password length must be greater than or equal to 8'
  },
  confirmPassword: {
    required: 'Required',
    match: 'Password does not match'
  },
  firstName: {
    required: 'First name is required'
  },
  lastName: {
    required: 'Last name is required'
  },
  companyName: {
    required: 'Company name  is required'
  },
  mobileNo: {
    required: 'Mobile number is required',
    minlength: "Mobile number must be 10 digit only",
    maxlength: "Mobile number must be 10 digit only",
    pattern: "Mobile number should be number only",
  },
  status: {
    required: 'Must select the status'
  },
  role: {
    required: 'User role is required'
  },
  name: {
    required: 'Name is required'
  },
  code: {
    required: 'Code is required'
  },
  price: {
    required: 'Price is required',
    pattern: "Price can be number only",
  },
  bstOfficePrice: {
    required: 'BST Office Price is required',
    pattern: "Price can be number only",
  },
  truckNo: {
    required: 'Truck Number is required',
  },
  lrNumber: {
    required: 'LR  Number is required',
  },
  lrAttachment: {
    required: 'LR attachment is required',
  },
  size: {
    required: 'Please attach a file.',
  },
  companyDetail: {
    required: 'Company Name is required',
  },
  contactName: {
    required: 'Contact person name is required',
  },
  contactNumber: {
    required: 'Contact Number is required.',
    minlength: "Contact Number must be 10 digit.",
    maxlength: "Contact Number must be 10 digit only.",
    pattern: 'Contact Number must be a number.'
  },
  pincode: {
    required: 'Pincode is required',
  },
  city: {
    required: 'City is required',
  },
  locality: {
    required: 'Locality is required',
  },
  buildingName: {
    required: 'BuildingName is required',
  },
  truckNumber: {
    required: 'Truck number is required',
  },
  capacity: {
    required: 'Available limit is required',
  },
  contactMobile: {
    required: 'Contact person Mobile number is required',
    minlength: "Mobile number must be 10 digit",
    maxlength: "Mobile number must be 10 digit only",
  },
  productId: {
    required: 'Product is required',
  },
  pickUpLocations: {
    required: 'Product is required',
  },
  estimate: {
    required: 'Order Estimate can not be blank',
  },
  otp: {
    required: 'OTP is required',
    minlength: 'OTP is invalid',
    maxlength: 'OTP is invalid',
    pattern: 'OTP must be a number'
  },
  godownDeliveryMinimumWeight: {
    required: 'Godown Delivery minimum weight is required',
    pattern: 'Weight must be a number'
  },
  godownDeliveryExtraCharge: {
    required: 'Godown Delivery Extra charge is required',
    pattern: 'Extra charge must be a number'
  },
  bookingMobile: {
    required: 'Booking mobile is required',
    pattern: 'Booking mobile must be a number'
  }
};
