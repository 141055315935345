import { Route } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { BstLayoutComponent } from '@bst-frontend/site-layout';
import { ViewOrderComponent, ViewTruckComponent } from '@bst-frontend/full-layout';


export const appRoutes: Route[] = [
  {
    path: '',
    children: [
      {
        path: 'login',
        title: 'Login',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@bst/bst-admin/login').then((m) => m.BstAdminLoginModule),
      },
      {
        path: 'order/view-order/:id',
        title: 'View Order',
        canActivate: [AuthGuard],
        component: ViewOrderComponent,
      },
      {
        path :'truck/view-truck/:id',
        title : 'View Truck',
        component : ViewTruckComponent
      },
      // {
      //   path: 'product/view-product/:id',
      //   title: 'View Product',
      //   canActivate: [AuthGuard],
      //   component: ViewOrderComponent,
      // },
      {
        path: '',
        component: BstLayoutComponent,
        canActivate: [AuthGuard],
        children: [
          // {
          //   path: 'dashboard',
          //   title: 'Dashboard',
          //   loadChildren: () =>
          //     import('@bst-frontend/bst-admin/dashboard').then(
          //       (m) => m.BstAdminDashboardModule
          //     ),
          // },
          {
            path: 'order',
            title: 'Order',
            loadChildren: () =>
              import('@bst-frontend/bst-admin/order').then(
                (m) => m.BstAdminOrderModule
              ),
          },
          {
            path: 'order-history',
            title: 'Order history',
            loadChildren: () =>
              import('@bst-frontend/bst-admin/order-history').then(
                (m) => m.BstAdminOrderHistoryModule
              ),
          },
          {
            path: 'truck',
            title: 'Truck',
            loadChildren: () =>
              import('@bst-frontend/bst-admin/truck').then(
                (m) => m.BstAdminTruckModule
              ),
          },
          {
            path: 'product',
            title: 'Product',
            loadChildren: () =>
              import('@bst-frontend/bst-admin/product').then(
                (m) => m.BstAdminProductModule
              ),
          },
          {
            path: 'user',
            title: 'User',
            loadChildren: () =>
              import('@bst-frontend/bst-admin/user').then(
                (m) => m.BstAdminUserModule
              ),
          },
          {
            path: 'address',
            title: 'Address',
            loadChildren: () =>
              import('@bst-frontend/bst-admin/address').then(
                (m) => m.BstAdminAddressModule
              ),
          },
          {
            path: 'app-settings',
            title: 'App Setting',
            loadChildren: () =>
              import('@bst-frontend/bst-admin/app-setting').then(
                (m) => m.BstAdminAppSettingModule
              ),
          },
          {
            path: '',
            redirectTo: '/order',
            pathMatch: 'full',
          },
        ],
      },
      // {
      //   path: 'order',
      //   component: BstLayoutComponent,
      //   canActivate: [AuthGuard],
      //   children: [
      //     {
      //       path: '',
      //       title: 'Order',
      //       loadChildren: () =>
      //         import('@bst-frontend/bst-admin/order').then(
      //           (m) => m.BstAdminOrderModule
      //         ),
      //     },
      //   ]},
      {
        path: 'product',
        component: BstLayoutComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'dashboard',
            title: 'Dashboard',
            loadChildren: () =>
              import('@bst-frontend/bst-admin/dashboard').then(
                (m) => m.BstAdminDashboardModule
              ),
          },
        ],
      },
    ],
  },
];
