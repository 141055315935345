import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient
  ) {}

  getUsers() {
    return this.http.get(`${this.environment.apiUrl}users/all`);
  }

  getProducts() {
    return this.http.get(`${this.environment.apiUrl}products/all`);
  }

  getAppDetails(){
    return this.http.get(`${this.environment.apiUrl}app-setting`);
  }

  createOrder(data: any) {
    return this.http.post(`${this.environment.apiUrl}orders`, data);
  }

  getUserAddresses(params: any) {
    return this.http.get(`${this.environment.apiUrl}addresses/1/3000`, {
      params: params,
    });
  }

  getTruckCapacities() {
    return this.http.get(`${this.environment.apiUrl}truck-cpacity/all`);
  }

  getOrderDetail(id: any): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}orders/${id}`);
  }

  updateOrderData(data: any, id: string, orderType: string) {
    return this.http.patch(
      `${this.environment.apiUrl}orders/${orderType}/${id}`,
      data
    );
  }

  updateOrderState(data: any, id: string, orderState: string) {
    return this.http.patch(
      `${this.environment.apiUrl}orders/${orderState}/${id}`,
      data
    );
  }

  downloadAttachment(filePath: string, fileName: string) {
    const actualPath =
      this.environment.apiUrl.replace('backend/', '') + filePath;

    return this.http
      .get(actualPath, { responseType: 'blob' })
      .subscribe((response: Blob) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(response);
        downloadLink.download = fileName;
        downloadLink.click();
      });
  }

  cancelOrder(id: string) {
    return this.http.patch(`${this.environment.apiUrl}orders/cancel/${id}`, {});
  }

  countOrders() {
    return this.http.get(`${this.environment.apiUrl}orders/count`);
  }

  //Add estimate
  addEstimate(data: any, id: string) {
    return this.http.patch(
      `${this.environment.apiUrl}orders/estimate/${id}`,
      data
    );
  }
 
  //Add counter offer
  addCounterEstimate(data: any, id: string) {
    return this.http.patch(
      `${this.environment.apiUrl}orders/rejected/${id}`,
      data
    );
  }
  // Add truck details
  addTruckDetails(data: any, id: string) {
    return this.http.patch(
      `${this.environment.apiUrl}orders/truck-assign/${id}`,
      data
    );
  }
  // loaded truck
  loadedTruck(data: any, id: string) {
    return this.http.patch(
      `${this.environment.apiUrl}orders/loaded/${id}`,
      data
    );
  }
  // Add invoice details
  addInvoiceDetails(data: any, id: string) {
    return this.http.patch(
      `${this.environment.apiUrl}orders/invoice-details/${id}`,
      data
    );
  }

  //Assign Vehicle
  assignVehicle(id: string, pickupId: string, data: any) {
    return this.http.patch(
      `${this.environment.apiUrl}orders/${id}/pickup-vehicle/${pickupId}`,
      data
    );
  }

  //Upload Invoice
  uploadInvoice(id: string, data: any) {
    return this.http.patch(
      `${this.environment.apiUrl}orders/invoice-upload/${id}`,
      data
    );
  }

  //Create Order Item
  createOrderItem(orderId: string, data: any){
    return this.http.post(`${this.environment.apiUrl}orders/${orderId}/item`, data);
  }

  //Update Order Item
  updateOrderItem(orderId: string, itemId: string, data: any){
    return this.http.patch(`${this.environment.apiUrl}orders/${orderId}/item/${itemId}`, data);
  }

  //Delete Order item
  deleteOrderItem(orderId: string, itemId: string){
    return this.http.delete(`${this.environment.apiUrl}orders/${orderId}/item/${itemId}`);
  }

  placeOrder(orderId: string, data = ''){
    return this.http.patch(`${this.environment.apiUrl}orders/${orderId}`, data);
  }
  
  getAllTruck(){
    return this.http.get(`${this.environment.apiUrl}trucks/all`);
  }
}
