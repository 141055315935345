import { FormControl, FormGroup } from '@angular/forms';
import { ElementRef, Inject, Injectable, inject } from '@angular/core';
import { Observable, fromEvent, merge, Subject, BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GenericValidator {
  public formErrSubject = new BehaviorSubject<any>(null);
  public formErrors$: Observable<any> = this.formErrSubject.asObservable();

  // By default the defined set of validation messages is pass but a custom message when the class is called can also be passed
  constructor(
    @Inject('validationMessages')
    private validationMessages: { [key: string]: { [key: string]: string } }
  ) {}

  // this will process each formcontrol in the form group
  // and then return the error message to display
  // the return value will be in this format `formControlName: 'error message'`;
  processMessages(container: FormGroup): { [key: string]: string } {
    const messages: any = {};
    // loop through all the formControls
    for (const controlKey in container.controls) {
      if (container.controls.hasOwnProperty(controlKey)) {
        // get the properties of each formControl
        const controlProperty = container.controls[controlKey];
        // If it is a FormGroup, process its child controls.
        if (controlProperty instanceof FormGroup) {
          const childMessages = this.processMessages(controlProperty);
          Object.assign(messages, childMessages);
        } else {
          // Only validate if there are validation messages for the control
          if (this.validationMessages[controlKey]) {
            messages[controlKey] = '';
            if (
              (controlProperty.dirty || controlProperty.touched) &&
              controlProperty.errors
            ) {
              // loop through the object of errors
              Object.keys(controlProperty.errors).map((messageKey) => {
                if (this.validationMessages[controlKey][messageKey]) {
                  messages[controlKey] +=
                    this.validationMessages[controlKey][messageKey] + ' ';
                }
              });
            }
          }
        }
      }
    }
    this.formErrSubject.next(messages);
    return messages;
  }

  watchFormBlurEvent(watchForm: FormGroup, formInputElements: ElementRef[]) {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = formInputElements.map(
      (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur')
    );

    // Merge the blur event observable with the valueChanges observable
    merge(watchForm.valueChanges, ...controlBlurs)
      .pipe(debounceTime(800))
      .subscribe((value) => {
        this.processMessages(watchForm);
      });
  }

  validateAllFormFields(formGroup: FormGroup) {
    console.log(formGroup);
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
        control.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        console.log({ control });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
