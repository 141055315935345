import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CONSTANT, TruckService } from '@bst-frontend/shared';
import { Subject } from 'rxjs';

@Component({
  selector: 'bst-frontend-view-truck',
  templateUrl: './view-truck.component.html',
  styleUrls: ['./view-truck.component.css'],
})
export class ViewTruckComponent implements OnInit {
  truckId!: string | null;
  truckData: any = {};
  orderType: any = CONSTANT.orderType;

  //User Address
  dataRender: Subject<string> = new Subject<string>();
  dataConfig: any = [];
  filterColumns: any;
  page = 'trucks/assigned/orders';
  nextPageUrl = 'order/view-order/';

  constructor(
    private truckService: TruckService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.truckId = this.activeRoute.snapshot.paramMap.get('id');
    this.page = `${this.page}/${this.truckId}`;
    if (this.truckId) {
      this.getDetails();
      this.setDataConfig();
    }
  }

  private setDataConfig() {
    this.dataConfig = {
      columns: [
        {
          title: 'NAME OF CUSTOMER',
          name: 'name',
          data: null,
          defaultContent: '',
          render: (data: any) => {
            return `<div class="order-detail">
            <h5>${this.orderType[data?.type]} - <span>${data?.orderNumber}</span></h5>
            <h6><span>${data?.orderBy?.companyName}</span></h6>
            <p> ${data?.orderBy?.name} - <span>${data?.orderBy?.mobileNo}</span></p>
            </div>`;
          },
        },
        // {
        //   title: 'ORDER ID',
        //   data: 'orderNumber',
        //   name: 'orderNumber',
        //   defaultContent: '',
        // },
        {
          title: 'TOTAL WEIGHT',
          data: null,
          name: 'totalWeight',
          defaultContent: '',
          render: (data: any) => {
            return `${data.totalWeight} KG`;
          },
        },
        {
          title: 'DATE & TIME',
          data: 'createdAt',
          name: 'createdAt',
          defaultContent: '',
          render: (data: string) => {
            return data
              ? new Date(data).toLocaleDateString('en-IN', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })
              : '';
          },
        },
        {
          title: 'STATUS',
          data: null,
          name: 'status',
          defaultContent: '',
          render: (data: any) => {
            let label = data?.state?.label;
            if(data.isLoaded && data.state.code === 'outForPickUp') {
              label = 'Loaded'
            }
            return `<span class="status ${this.statusClass(data?.state?.code)}">
                    ${label}
              </span>`;
          },
        },
      ],
      fixedColumns: {
        right: 1,
        left: 0,
      },
    };
  }

  statusClass(state: string) {
    const statusData: any = {
      requested: 'status-requested',
      estimated: 'status-confirmation',
      inProcess: 'status-inprocess',
      rejected: 'status-counter-Offer',
      'truck-assigned': 'status-vehicleassign',
      outForPickUp: 'status-vehiclethere',
      dispatched: 'status-dispatch',
      delivered: 'status-delivered',
      paid: 'status-orderpaid',
      cancelled: 'status-ordercancel',
    };
    return statusData[state];
  }

  getDetails() {
    this.truckService.getAssignTruckDetail(this.truckId).subscribe({
      next: (resp) => {
        this.truckData = resp.data;
      },
    });
  }
}
