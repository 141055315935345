import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { siteLayoutRoutes } from './lib.routes';
import { BstLayoutComponent } from './bst-layout/bst-layout.component';
import { CommonUiModule } from '@bst-frontend/common-ui';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(siteLayoutRoutes),
    RouterModule,
    CommonUiModule
  ],
  declarations: [BstLayoutComponent],
  exports: [BstLayoutComponent],
})
export class SiteLayoutModule {}
