import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient
  ) {

  }

  getProductDetail(id: any): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}products/${id}`);
  }

  saveProduct(data: any, id: string,) {
    if (id) {
      return this.http.patch(`${this.environment.apiUrl}products/${id}`, data);
    } else {
      return this.http.post(`${this.environment.apiUrl}products`, data);
    }
  }
}
