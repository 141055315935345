import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule} from '@angular/router';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { fullLayoutRoutes } from './lib.routes';
import { ViewOrderComponent } from './view-order/view-order.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonUiModule } from '@bst-frontend/common-ui';
import { ViewTruckComponent } from './view-truck/view-truck.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(fullLayoutRoutes),
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    CommonUiModule
  ],
  declarations: [ViewOrderComponent, ViewTruckComponent],
  exports: [],
})
export class FullLayoutModule {}
